import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/clientes',
    component: () => import('../views/ConsultaClientes.vue')
  },
  {
    path: '/view/:id',
    component: () => import('../views/MostrarDados.vue')
  },
  {
    path: '/new',
    component: () => import('../views/EditarCadastro.vue')
  },
  {
    path: '/edit/:id',
    component: () => import('../views/EditarCadastro.vue')
  },
  {
    path: '/about',
    component: () => import('../views/About.vue')
  },
  {
    path: '/comprar',
    component: () => import('../views/Comprar.vue')
  },
  {
    path: '/parceiros',
    component: () => import('../views/Parceiros.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
